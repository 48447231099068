$border-color: #2c2d28;
$border-width: 10px;

$menu-color: #efefef;

$link-color: #fe2700;
$text-color: #2a2a2c;

$outline-color: #838383;

$album-details-width: 600px;

$track-listing-width: 700px;
$track-listing-odd-color: #fff;
$track-listing-even-color: #e4e4e4;
$track-listing-margin: 20px;
$track-listing-padding: 1em;
$track-listing-spacing: 5px;