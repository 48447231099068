@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';

@import 'base/fonts';
@import 'base/variables';

@import '../../node_modules/lightslider/src/css/lightslider';

/*
Global
*/

body {
    background: url('/assets/img/background-48.jpg');
    background-color:rgba(0, 0, 0, 0.5);
    color: $text-color;
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    padding-top: 50px;
}

a {
    color: $text-color;
    transition: all .2s linear;
}
a:hover { color: $link-color; }

h1 {
    font-family: 'Rock Salt', cursive;
    line-height: 1.6em;
    text-transform: uppercase;
}

/*
Borders
*/

.black-bar-bottom, .black-bar-left, .black-bar-right {
    background: $border-color;
    display: none;
    position: fixed;
    z-index: 999;
}
.black-bar-left, .black-bar-right {
    bottom: 0;
    top: 0;
    width: $border-width;
}
.black-bar-left { left: 0; }
.black-bar-right { right: 0; }
.black-bar-bottom {
    height: $border-width;
    left: 0;
    right: 0;
}
.black-bar-bottom { bottom: 0; }

/*
Navbar
*/

.navbar-inverse {
    background: url('/assets/img/menu-background.jpg');
    border: 0;
    padding: 0;
}
.navbar-collapse { text-align: center; } 
a.menu-item.music { float: left; }
a.menu-item.news { float: right; }
a.menu-item {
    font-family: 'Amatic SC', cursive;
    font-size: 33px;
    line-height: 1em;
    margin-top: 6px;
    text-transform: uppercase;
}
a.menu-item,
a.menu-item:active,
a.menu-item:hover,
a.menu-item:visited {
    color: $menu-color;
    text-decoration: none;
}
a.menu-item:after {
    border-bottom: 1px solid $menu-color;
    content: '';
    display: block;
    transform: scaleX(0.0001);  
    transition: transform 250ms ease-in-out;
}
a.menu-item:hover:after,
a.menu-item.active:after { transform: scaleX(1)!important; }
.logo {
    background: url(/assets/img/header.png);
    background-size: cover;
    display: inline-block;
    height: 44px;
    width: 170px; 
}
.navbar-social {
    background: url('/assets/img/social-background.jpg');
    -webkit-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.5);
    padding: $border-width;
    text-align: center;

    a {
        color: $menu-color;
        cursor: pointer;
        font-size: 17px;
        margin: 0 6px; 
        text-decoration: none;        
    }
}

/*
Page Content
*/

.page-content { padding: 68px 0 0; } 

/*
MUSIC PAGE
*/

.album-covers {
    a,
    a:visited,
    a:hover,
    a:active { text-decoration: none!important; }
    
    li { width:125px; }
    li.active-album {
        a {
            color: $link-color;
            text-decoration: none!important;
        }
    }
    
    img {
        border: 1px solid $outline-color;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        height: 125px;
        margin: 5px;
        transition: all .2s linear;
    }
    a:hover img,
    li.active-album img {
        border-color: $link-color;
        box-shadow: 0px 0px 5px 0px rgba(254,39,0,0.75);
    }

    div { padding: 5px 5px 0; }

    a .year { color: $text-color; }
}

.lSSlideOuter {
    .lSPager.lSpg > li:hover a,
    .lSPager.lSpg > li.active a { background: $link-color; }
    .lSPager.lSpg > li a { background: $outline-color; }
}

.album-details {
    text-align: center;

    a,
    a:visited,
    a:hover,
    a:active { text-decoration: none!important; }

    h3.download-album {
        margin-bottom: $track-listing-margin; 
        a {
            color: $link-color;
            margin-bottom: $track-listing-margin;
            text-transform: uppercase;
        }
    } 
}

.loading {
    padding-top: 40px;
}

.track-listing {
    font-size: 12px;
    margin: 0 auto $track-listing-margin;
    max-width: $track-listing-width;
    text-align: left;

    ul {
        list-style: none;
        margin: 0;
        overflow: hidden;
        padding: 0;
    }

    li {
        background: $track-listing-odd-color;
        
        span {
            background: $track-listing-odd-color;
            padding: 0 $track-listing-spacing 0 0;
            position: relative;
        }
        span.length {
            float: right;
            padding: 0 $track-listing-padding 0 $track-listing-spacing; 
        }
        .fa { padding-right: $track-listing-spacing; }
    }
    li.info { padding: $track-listing-padding 0 $track-listing-padding $track-listing-padding; }
    li.info:after {
        overflow: visible;
        direction: ltr;
        margin: 0;
        float: left;
        width: 0;
        white-space: nowrap;
        content: ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . ";
    }
    li.audio {
        display: none;
        padding: 0 $track-listing-padding $track-listing-padding 2.5em;
        width: 100%;
        audio { width: 100%; }
    }

    li.even {
        background: $track-listing-even-color;
        
        span { background: $track-listing-even-color; }
    }

    a,
    a:visited { color: $text-color; }
    a:hover, a.active { color: $link-color; }

    a.play svg {
        margin: 0 7px 0 5px;
    }
}

.album-details > img {
    border-color: $outline-color;
    border-style: solid;
    border-width: 1px 0;
    margin: 40px 0 30px 0;
    max-width: $album-details-width;
    width: 100%;
}
@media (min-width: $album-details-width) {
    .album-details > img {
        border-width: 1px;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    }
}

/*
NEWS PAGE
 */

.post {
    background-image: url(/assets/img/hr.png);
    background-position: bottom center;
    background-size: auto 13px;
    background-repeat: no-repeat;
    padding: 4em 0;
    text-align: center;
    
    ul { list-style-position: inside; } 

    .text {
        margin-top: 1em;
        a {
            color: $link-color;
        }
    }
}

/*
Tablet
*/

@media (min-width: $screen-sm-min) {
    .black-bar-bottom, .black-bar-left, .black-bar-right { display: block; }

    .navbar-inverse { padding: $border-width $border-width 0 $border-width; }

    .track-listing { font-size: 14px; }
}

/*
Desktop
*/

@media (min-width: $screen-md-min) {
    .logo {
        height: 158px;
        width: 607px;
    }
    
    a.menu-item {
        font-size: 72px;
        margin-top: 44px;
    }
    a.menu-item:after { border-bottom-width: 2px; }

    .page-content { padding: 195px 15px 15px; }

    .album-covers {
        li { width: 250px; }
        img { height: 250px; }
    }

    .track-listing { font-size: 18px; }
}