// List Icons
// -------------------------

.#{$el-css-prefix}-ul {
  padding-left: 0;
  margin-left: $el-li-width;
  list-style-type: none;
  > li { position: relative; }
}
.#{$el-css-prefix}-li {
  position: absolute;
  left: -$el-li-width;
  width: $el-li-width;
  top: (2em / 14);
  text-align: center;
  &.#{$el-css-prefix}-lg {
    left: -$el-li-width + (4em / 14);
  }
}
