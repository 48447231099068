/* Elusive Icons uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */

.#{$el-css-prefix}-address-book-alt:before { content: $el-var-address-book-alt; }
.#{$el-css-prefix}-address-book:before { content: $el-var-address-book; }
.#{$el-css-prefix}-adjust-alt:before { content: $el-var-adjust-alt; }
.#{$el-css-prefix}-adjust:before { content: $el-var-adjust; }
.#{$el-css-prefix}-adult:before { content: $el-var-adult; }
.#{$el-css-prefix}-align-center:before { content: $el-var-align-center; }
.#{$el-css-prefix}-align-justify:before { content: $el-var-align-justify; }
.#{$el-css-prefix}-align-left:before { content: $el-var-align-left; }
.#{$el-css-prefix}-align-right:before { content: $el-var-align-right; }
.#{$el-css-prefix}-arrow-down:before { content: $el-var-arrow-down; }
.#{$el-css-prefix}-arrow-left:before { content: $el-var-arrow-left; }
.#{$el-css-prefix}-arrow-right:before { content: $el-var-arrow-right; }
.#{$el-css-prefix}-arrow-up:before { content: $el-var-arrow-up; }
.#{$el-css-prefix}-asl:before { content: $el-var-asl; }
.#{$el-css-prefix}-asterisk:before { content: $el-var-asterisk; }
.#{$el-css-prefix}-backward:before { content: $el-var-backward; }
.#{$el-css-prefix}-ban-circle:before { content: $el-var-ban-circle; }
.#{$el-css-prefix}-barcode:before { content: $el-var-barcode; }
.#{$el-css-prefix}-behance:before { content: $el-var-behance; }
.#{$el-css-prefix}-bell:before { content: $el-var-bell; }
.#{$el-css-prefix}-blind:before { content: $el-var-blind; }
.#{$el-css-prefix}-blogger:before { content: $el-var-blogger; }
.#{$el-css-prefix}-bold:before { content: $el-var-bold; }
.#{$el-css-prefix}-book:before { content: $el-var-book; }
.#{$el-css-prefix}-bookmark-empty:before { content: $el-var-bookmark-empty; }
.#{$el-css-prefix}-bookmark:before { content: $el-var-bookmark; }
.#{$el-css-prefix}-braille:before { content: $el-var-braille; }
.#{$el-css-prefix}-briefcase:before { content: $el-var-briefcase; }
.#{$el-css-prefix}-broom:before { content: $el-var-broom; }
.#{$el-css-prefix}-brush:before { content: $el-var-brush; }
.#{$el-css-prefix}-bulb:before { content: $el-var-bulb; }
.#{$el-css-prefix}-bullhorn:before { content: $el-var-bullhorn; }
.#{$el-css-prefix}-calendar-sign:before { content: $el-var-calendar-sign; }
.#{$el-css-prefix}-calendar:before { content: $el-var-calendar; }
.#{$el-css-prefix}-camera:before { content: $el-var-camera; }
.#{$el-css-prefix}-car:before { content: $el-var-car; }
.#{$el-css-prefix}-caret-down:before { content: $el-var-caret-down; }
.#{$el-css-prefix}-caret-left:before { content: $el-var-caret-left; }
.#{$el-css-prefix}-caret-right:before { content: $el-var-caret-right; }
.#{$el-css-prefix}-caret-up:before { content: $el-var-caret-up; }
.#{$el-css-prefix}-cc:before { content: $el-var-cc; }
.#{$el-css-prefix}-certificate:before { content: $el-var-certificate; }
.#{$el-css-prefix}-check-empty:before { content: $el-var-check-empty; }
.#{$el-css-prefix}-check:before { content: $el-var-check; }
.#{$el-css-prefix}-chevron-down:before { content: $el-var-chevron-down; }
.#{$el-css-prefix}-chevron-left:before { content: $el-var-chevron-left; }
.#{$el-css-prefix}-chevron-right:before { content: $el-var-chevron-right; }
.#{$el-css-prefix}-chevron-up:before { content: $el-var-chevron-up; }
.#{$el-css-prefix}-child:before { content: $el-var-child; }
.#{$el-css-prefix}-circle-arrow-down:before { content: $el-var-circle-arrow-down; }
.#{$el-css-prefix}-circle-arrow-left:before { content: $el-var-circle-arrow-left; }
.#{$el-css-prefix}-circle-arrow-right:before { content: $el-var-circle-arrow-right; }
.#{$el-css-prefix}-circle-arrow-up:before { content: $el-var-circle-arrow-up; }
.#{$el-css-prefix}-cloud-alt:before { content: $el-var-cloud-alt; }
.#{$el-css-prefix}-cloud:before { content: $el-var-cloud; }
.#{$el-css-prefix}-cog-alt:before { content: $el-var-cog-alt; }
.#{$el-css-prefix}-cog:before { content: $el-var-cog; }
.#{$el-css-prefix}-cogs:before { content: $el-var-cogs; }
.#{$el-css-prefix}-comment-alt:before { content: $el-var-comment-alt; }
.#{$el-css-prefix}-comment:before { content: $el-var-comment; }
.#{$el-css-prefix}-compass-alt:before { content: $el-var-compass-alt; }
.#{$el-css-prefix}-compass:before { content: $el-var-compass; }
.#{$el-css-prefix}-credit-card:before { content: $el-var-credit-card; }
.#{$el-css-prefix}-css:before { content: $el-var-css; }
.#{$el-css-prefix}-dashboard:before { content: $el-var-dashboard; }
.#{$el-css-prefix}-delicious:before { content: $el-var-delicious; }
.#{$el-css-prefix}-deviantart:before { content: $el-var-deviantart; }
.#{$el-css-prefix}-digg:before { content: $el-var-digg; }
.#{$el-css-prefix}-download-alt:before { content: $el-var-download-alt; }
.#{$el-css-prefix}-download:before { content: $el-var-download; }
.#{$el-css-prefix}-dribbble:before { content: $el-var-dribbble; }
.#{$el-css-prefix}-edit:before { content: $el-var-edit; }
.#{$el-css-prefix}-eject:before { content: $el-var-eject; }
.#{$el-css-prefix}-envelope-alt:before { content: $el-var-envelope-alt; }
.#{$el-css-prefix}-envelope:before { content: $el-var-envelope; }
.#{$el-css-prefix}-error-alt:before { content: $el-var-error-alt; }
.#{$el-css-prefix}-error:before { content: $el-var-error; }
.#{$el-css-prefix}-eur:before { content: $el-var-eur; }
.#{$el-css-prefix}-exclamation-sign:before { content: $el-var-exclamation-sign; }
.#{$el-css-prefix}-eye-close:before { content: $el-var-eye-close; }
.#{$el-css-prefix}-eye-open:before { content: $el-var-eye-open; }
.#{$el-css-prefix}-facebook:before { content: $el-var-facebook; }
.#{$el-css-prefix}-facetime-video:before { content: $el-var-facetime-video; }
.#{$el-css-prefix}-fast-backward:before { content: $el-var-fast-backward; }
.#{$el-css-prefix}-fast-forward:before { content: $el-var-fast-forward; }
.#{$el-css-prefix}-female:before { content: $el-var-female; }
.#{$el-css-prefix}-file-alt:before { content: $el-var-file-alt; }
.#{$el-css-prefix}-file-edit-alt:before { content: $el-var-file-edit-alt; }
.#{$el-css-prefix}-file-edit:before { content: $el-var-file-edit; }
.#{$el-css-prefix}-file-new-alt:before { content: $el-var-file-new-alt; }
.#{$el-css-prefix}-file-new:before { content: $el-var-file-new; }
.#{$el-css-prefix}-file:before { content: $el-var-file; }
.#{$el-css-prefix}-film:before { content: $el-var-film; }
.#{$el-css-prefix}-filter:before { content: $el-var-filter; }
.#{$el-css-prefix}-fire:before { content: $el-var-fire; }
.#{$el-css-prefix}-flag-alt:before { content: $el-var-flag-alt; }
.#{$el-css-prefix}-flag:before { content: $el-var-flag; }
.#{$el-css-prefix}-flickr:before { content: $el-var-flickr; }
.#{$el-css-prefix}-folder-close:before { content: $el-var-folder-close; }
.#{$el-css-prefix}-folder-open:before { content: $el-var-folder-open; }
.#{$el-css-prefix}-folder-sign:before { content: $el-var-folder-sign; }
.#{$el-css-prefix}-folder:before { content: $el-var-folder; }
.#{$el-css-prefix}-font:before { content: $el-var-font; }
.#{$el-css-prefix}-fontsize:before { content: $el-var-fontsize; }
.#{$el-css-prefix}-fork:before { content: $el-var-fork; }
.#{$el-css-prefix}-forward-alt:before { content: $el-var-forward-alt; }
.#{$el-css-prefix}-forward:before { content: $el-var-forward; }
.#{$el-css-prefix}-foursquare:before { content: $el-var-foursquare; }
.#{$el-css-prefix}-friendfeed-rect:before { content: $el-var-friendfeed-rect; }
.#{$el-css-prefix}-friendfeed:before { content: $el-var-friendfeed; }
.#{$el-css-prefix}-fullscreen:before { content: $el-var-fullscreen; }
.#{$el-css-prefix}-gbp:before { content: $el-var-gbp; }
.#{$el-css-prefix}-gift:before { content: $el-var-gift; }
.#{$el-css-prefix}-github-text:before { content: $el-var-github-text; }
.#{$el-css-prefix}-github:before { content: $el-var-github; }
.#{$el-css-prefix}-glass:before { content: $el-var-glass; }
.#{$el-css-prefix}-glasses:before { content: $el-var-glasses; }
.#{$el-css-prefix}-globe-alt:before { content: $el-var-globe-alt; }
.#{$el-css-prefix}-globe:before { content: $el-var-globe; }
.#{$el-css-prefix}-googleplus:before { content: $el-var-googleplus; }
.#{$el-css-prefix}-graph-alt:before { content: $el-var-graph-alt; }
.#{$el-css-prefix}-graph:before { content: $el-var-graph; }
.#{$el-css-prefix}-group-alt:before { content: $el-var-group-alt; }
.#{$el-css-prefix}-group:before { content: $el-var-group; }
.#{$el-css-prefix}-guidedog:before { content: $el-var-guidedog; }
.#{$el-css-prefix}-hand-down:before { content: $el-var-hand-down; }
.#{$el-css-prefix}-hand-left:before { content: $el-var-hand-left; }
.#{$el-css-prefix}-hand-right:before { content: $el-var-hand-right; }
.#{$el-css-prefix}-hand-up:before { content: $el-var-hand-up; }
.#{$el-css-prefix}-hdd:before { content: $el-var-hdd; }
.#{$el-css-prefix}-headphones:before { content: $el-var-headphones; }
.#{$el-css-prefix}-hearing-impaired:before { content: $el-var-hearing-impaired; }
.#{$el-css-prefix}-heart-alt:before { content: $el-var-heart-alt; }
.#{$el-css-prefix}-heart-empty:before { content: $el-var-heart-empty; }
.#{$el-css-prefix}-heart:before { content: $el-var-heart; }
.#{$el-css-prefix}-home-alt:before { content: $el-var-home-alt; }
.#{$el-css-prefix}-home:before { content: $el-var-home; }
.#{$el-css-prefix}-hourglass:before { content: $el-var-hourglass; }
.#{$el-css-prefix}-idea-alt:before { content: $el-var-idea-alt; }
.#{$el-css-prefix}-idea:before { content: $el-var-idea; }
.#{$el-css-prefix}-inbox-alt:before { content: $el-var-inbox-alt; }
.#{$el-css-prefix}-inbox-box:before { content: $el-var-inbox-box; }
.#{$el-css-prefix}-inbox:before { content: $el-var-inbox; }
.#{$el-css-prefix}-indent-left:before { content: $el-var-indent-left; }
.#{$el-css-prefix}-indent-right:before { content: $el-var-indent-right; }
.#{$el-css-prefix}-info-circle:before { content: $el-var-info-circle; }
.#{$el-css-prefix}-instagram:before { content: $el-var-instagram; }
.#{$el-css-prefix}-iphone-home:before { content: $el-var-iphone-home; }
.#{$el-css-prefix}-italic:before { content: $el-var-italic; }
.#{$el-css-prefix}-key:before { content: $el-var-key; }
.#{$el-css-prefix}-laptop-alt:before { content: $el-var-laptop-alt; }
.#{$el-css-prefix}-laptop:before { content: $el-var-laptop; }
.#{$el-css-prefix}-lastfm:before { content: $el-var-lastfm; }
.#{$el-css-prefix}-leaf:before { content: $el-var-leaf; }
.#{$el-css-prefix}-lines:before { content: $el-var-lines; }
.#{$el-css-prefix}-link:before { content: $el-var-link; }
.#{$el-css-prefix}-linkedin:before { content: $el-var-linkedin; }
.#{$el-css-prefix}-list-alt:before { content: $el-var-list-alt; }
.#{$el-css-prefix}-list:before { content: $el-var-list; }
.#{$el-css-prefix}-livejournal:before { content: $el-var-livejournal; }
.#{$el-css-prefix}-lock-alt:before { content: $el-var-lock-alt; }
.#{$el-css-prefix}-lock:before { content: $el-var-lock; }
.#{$el-css-prefix}-magic:before { content: $el-var-magic; }
.#{$el-css-prefix}-magnet:before { content: $el-var-magnet; }
.#{$el-css-prefix}-male:before { content: $el-var-male; }
.#{$el-css-prefix}-map-marker-alt:before { content: $el-var-map-marker-alt; }
.#{$el-css-prefix}-map-marker:before { content: $el-var-map-marker; }
.#{$el-css-prefix}-mic-alt:before { content: $el-var-mic-alt; }
.#{$el-css-prefix}-mic:before { content: $el-var-mic; }
.#{$el-css-prefix}-minus-sign:before { content: $el-var-minus-sign; }
.#{$el-css-prefix}-minus:before { content: $el-var-minus; }
.#{$el-css-prefix}-move:before { content: $el-var-move; }
.#{$el-css-prefix}-music:before { content: $el-var-music; }
.#{$el-css-prefix}-myspace:before { content: $el-var-myspace; }
.#{$el-css-prefix}-network:before { content: $el-var-network; }
.#{$el-css-prefix}-off:before { content: $el-var-off; }
.#{$el-css-prefix}-ok-circle:before { content: $el-var-ok-circle; }
.#{$el-css-prefix}-ok-sign:before { content: $el-var-ok-sign; }
.#{$el-css-prefix}-ok:before { content: $el-var-ok; }
.#{$el-css-prefix}-opensource:before { content: $el-var-opensource; }
.#{$el-css-prefix}-paper-clip-alt:before { content: $el-var-paper-clip-alt; }
.#{$el-css-prefix}-paper-clip:before { content: $el-var-paper-clip; }
.#{$el-css-prefix}-path:before { content: $el-var-path; }
.#{$el-css-prefix}-pause-alt:before { content: $el-var-pause-alt; }
.#{$el-css-prefix}-pause:before { content: $el-var-pause; }
.#{$el-css-prefix}-pencil-alt:before { content: $el-var-pencil-alt; }
.#{$el-css-prefix}-pencil:before { content: $el-var-pencil; }
.#{$el-css-prefix}-person:before { content: $el-var-person; }
.#{$el-css-prefix}-phone-alt:before { content: $el-var-phone-alt; }
.#{$el-css-prefix}-phone:before { content: $el-var-phone; }
.#{$el-css-prefix}-photo-alt:before { content: $el-var-photo-alt; }
.#{$el-css-prefix}-photo:before { content: $el-var-photo; }
.#{$el-css-prefix}-picasa:before { content: $el-var-picasa; }
.#{$el-css-prefix}-picture:before { content: $el-var-picture; }
.#{$el-css-prefix}-pinterest:before { content: $el-var-pinterest; }
.#{$el-css-prefix}-plane:before { content: $el-var-plane; }
.#{$el-css-prefix}-play-alt:before { content: $el-var-play-alt; }
.#{$el-css-prefix}-play-circle:before { content: $el-var-play-circle; }
.#{$el-css-prefix}-play:before { content: $el-var-play; }
.#{$el-css-prefix}-plurk-alt:before { content: $el-var-plurk-alt; }
.#{$el-css-prefix}-plurk:before { content: $el-var-plurk; }
.#{$el-css-prefix}-plus-sign:before { content: $el-var-plus-sign; }
.#{$el-css-prefix}-plus:before { content: $el-var-plus; }
.#{$el-css-prefix}-podcast:before { content: $el-var-podcast; }
.#{$el-css-prefix}-print:before { content: $el-var-print; }
.#{$el-css-prefix}-puzzle:before { content: $el-var-puzzle; }
.#{$el-css-prefix}-qrcode:before { content: $el-var-qrcode; }
.#{$el-css-prefix}-question-sign:before { content: $el-var-question-sign; }
.#{$el-css-prefix}-question:before { content: $el-var-question; }
.#{$el-css-prefix}-quote-alt:before { content: $el-var-quote-alt; }
.#{$el-css-prefix}-quote-right-alt:before { content: $el-var-quote-right-alt; }
.#{$el-css-prefix}-quote-right:before { content: $el-var-quote-right; }
.#{$el-css-prefix}-quotes:before { content: $el-var-quotes; }
.#{$el-css-prefix}-random:before { content: $el-var-random; }
.#{$el-css-prefix}-record:before { content: $el-var-record; }
.#{$el-css-prefix}-reddit:before { content: $el-var-reddit; }
.#{$el-css-prefix}-redux:before { content: $el-var-redux; }
.#{$el-css-prefix}-refresh:before { content: $el-var-refresh; }
.#{$el-css-prefix}-remove-circle:before { content: $el-var-remove-circle; }
.#{$el-css-prefix}-remove-sign:before { content: $el-var-remove-sign; }
.#{$el-css-prefix}-remove:before { content: $el-var-remove; }
.#{$el-css-prefix}-repeat-alt:before { content: $el-var-repeat-alt; }
.#{$el-css-prefix}-repeat:before { content: $el-var-repeat; }
.#{$el-css-prefix}-resize-full:before { content: $el-var-resize-full; }
.#{$el-css-prefix}-resize-horizontal:before { content: $el-var-resize-horizontal; }
.#{$el-css-prefix}-resize-small:before { content: $el-var-resize-small; }
.#{$el-css-prefix}-resize-vertical:before { content: $el-var-resize-vertical; }
.#{$el-css-prefix}-return-key:before { content: $el-var-return-key; }
.#{$el-css-prefix}-retweet:before { content: $el-var-retweet; }
.#{$el-css-prefix}-reverse-alt:before { content: $el-var-reverse-alt; }
.#{$el-css-prefix}-road:before { content: $el-var-road; }
.#{$el-css-prefix}-rss:before { content: $el-var-rss; }
.#{$el-css-prefix}-scissors:before { content: $el-var-scissors; }
.#{$el-css-prefix}-screen-alt:before { content: $el-var-screen-alt; }
.#{$el-css-prefix}-screen:before { content: $el-var-screen; }
.#{$el-css-prefix}-screenshot:before { content: $el-var-screenshot; }
.#{$el-css-prefix}-search-alt:before { content: $el-var-search-alt; }
.#{$el-css-prefix}-search:before { content: $el-var-search; }
.#{$el-css-prefix}-share-alt:before { content: $el-var-share-alt; }
.#{$el-css-prefix}-share:before { content: $el-var-share; }
.#{$el-css-prefix}-shopping-cart-sign:before { content: $el-var-shopping-cart-sign; }
.#{$el-css-prefix}-shopping-cart:before { content: $el-var-shopping-cart; }
.#{$el-css-prefix}-signal:before { content: $el-var-signal; }
.#{$el-css-prefix}-skype:before { content: $el-var-skype; }
.#{$el-css-prefix}-slideshare:before { content: $el-var-slideshare; }
.#{$el-css-prefix}-smiley-alt:before { content: $el-var-smiley-alt; }
.#{$el-css-prefix}-smiley:before { content: $el-var-smiley; }
.#{$el-css-prefix}-soundcloud:before { content: $el-var-soundcloud; }
.#{$el-css-prefix}-speaker:before { content: $el-var-speaker; }
.#{$el-css-prefix}-spotify:before { content: $el-var-spotify; }
.#{$el-css-prefix}-stackoverflow:before { content: $el-var-stackoverflow; }
.#{$el-css-prefix}-star-alt:before { content: $el-var-star-alt; }
.#{$el-css-prefix}-star-empty:before { content: $el-var-star-empty; }
.#{$el-css-prefix}-star:before { content: $el-var-star; }
.#{$el-css-prefix}-step-backward:before { content: $el-var-step-backward; }
.#{$el-css-prefix}-step-forward:before { content: $el-var-step-forward; }
.#{$el-css-prefix}-stop-alt:before { content: $el-var-stop-alt; }
.#{$el-css-prefix}-stop:before { content: $el-var-stop; }
.#{$el-css-prefix}-stumbleupon:before { content: $el-var-stumbleupon; }
.#{$el-css-prefix}-tag:before { content: $el-var-tag; }
.#{$el-css-prefix}-tags:before { content: $el-var-tags; }
.#{$el-css-prefix}-tasks:before { content: $el-var-tasks; }
.#{$el-css-prefix}-text-height:before { content: $el-var-text-height; }
.#{$el-css-prefix}-text-width:before { content: $el-var-text-width; }
.#{$el-css-prefix}-th-large:before { content: $el-var-th-large; }
.#{$el-css-prefix}-th-list:before { content: $el-var-th-list; }
.#{$el-css-prefix}-th:before { content: $el-var-th; }
.#{$el-css-prefix}-thumbs-down:before { content: $el-var-thumbs-down; }
.#{$el-css-prefix}-thumbs-up:before { content: $el-var-thumbs-up; }
.#{$el-css-prefix}-time-alt:before { content: $el-var-time-alt; }
.#{$el-css-prefix}-time:before { content: $el-var-time; }
.#{$el-css-prefix}-tint:before { content: $el-var-tint; }
.#{$el-css-prefix}-torso:before { content: $el-var-torso; }
.#{$el-css-prefix}-trash-alt:before { content: $el-var-trash-alt; }
.#{$el-css-prefix}-trash:before { content: $el-var-trash; }
.#{$el-css-prefix}-tumblr:before { content: $el-var-tumblr; }
.#{$el-css-prefix}-twitter:before { content: $el-var-twitter; }
.#{$el-css-prefix}-universal-access:before { content: $el-var-universal-access; }
.#{$el-css-prefix}-unlock-alt:before { content: $el-var-unlock-alt; }
.#{$el-css-prefix}-unlock:before { content: $el-var-unlock; }
.#{$el-css-prefix}-upload:before { content: $el-var-upload; }
.#{$el-css-prefix}-usd:before { content: $el-var-usd; }
.#{$el-css-prefix}-user:before { content: $el-var-user; }
.#{$el-css-prefix}-viadeo:before { content: $el-var-viadeo; }
.#{$el-css-prefix}-video-alt:before { content: $el-var-video-alt; }
.#{$el-css-prefix}-video-chat:before { content: $el-var-video-chat; }
.#{$el-css-prefix}-video:before { content: $el-var-video; }
.#{$el-css-prefix}-view-mode:before { content: $el-var-view-mode; }
.#{$el-css-prefix}-vimeo:before { content: $el-var-vimeo; }
.#{$el-css-prefix}-vkontakte:before { content: $el-var-vkontakte; }
.#{$el-css-prefix}-volume-down:before { content: $el-var-volume-down; }
.#{$el-css-prefix}-volume-off:before { content: $el-var-volume-off; }
.#{$el-css-prefix}-volume-up:before { content: $el-var-volume-up; }
.#{$el-css-prefix}-w3c:before { content: $el-var-w3c; }
.#{$el-css-prefix}-warning-sign:before { content: $el-var-warning-sign; }
.#{$el-css-prefix}-website-alt:before { content: $el-var-website-alt; }
.#{$el-css-prefix}-website:before { content: $el-var-website; }
.#{$el-css-prefix}-wheelchair:before { content: $el-var-wheelchair; }
.#{$el-css-prefix}-wordpress:before { content: $el-var-wordpress; }
.#{$el-css-prefix}-wrench-alt:before { content: $el-var-wrench-alt; }
.#{$el-css-prefix}-wrench:before { content: $el-var-wrench; }
.#{$el-css-prefix}-youtube:before { content: $el-var-youtube; }
.#{$el-css-prefix}-zoom-in:before { content: $el-var-zoom-in; }
.#{$el-css-prefix}-zoom-out:before { content: $el-var-zoom-out; }
