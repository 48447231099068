// Variables
// --------------------------

$el-font-path:        "/assets/fonts/elusive-icons" !default;
$el-font-size-base:   14px !default;
//$el-font-path:        "//netdna.bootstrapcdn.com/elusive-icons/2.0.0/fonts" !default; // for referencing Bootstrap CDN font files directly
$el-css-prefix:       el !default;
$el-version:          "2.0.0" !default;
$el-border-color:     #eee !default;
$el-inverse:          #fff !default;
$el-li-width:         (30em / 14) !default;

$el-var-address-book: "\f102";
$el-var-address-book-alt: "\f101";
$el-var-adjust: "\f104";
$el-var-adjust-alt: "\f103";
$el-var-adult: "\f105";
$el-var-align-center: "\f106";
$el-var-align-justify: "\f107";
$el-var-align-left: "\f108";
$el-var-align-right: "\f109";
$el-var-arrow-down: "\f10a";
$el-var-arrow-left: "\f10b";
$el-var-arrow-right: "\f10c";
$el-var-arrow-up: "\f10d";
$el-var-asl: "\f10e";
$el-var-asterisk: "\f10f";
$el-var-backward: "\f110";
$el-var-ban-circle: "\f111";
$el-var-barcode: "\f112";
$el-var-behance: "\f113";
$el-var-bell: "\f114";
$el-var-blind: "\f115";
$el-var-blogger: "\f116";
$el-var-bold: "\f117";
$el-var-book: "\f118";
$el-var-bookmark: "\f11a";
$el-var-bookmark-empty: "\f119";
$el-var-braille: "\f11b";
$el-var-briefcase: "\f11c";
$el-var-broom: "\f11d";
$el-var-brush: "\f11e";
$el-var-bulb: "\f11f";
$el-var-bullhorn: "\f120";
$el-var-calendar: "\f122";
$el-var-calendar-sign: "\f121";
$el-var-camera: "\f123";
$el-var-car: "\f124";
$el-var-caret-down: "\f125";
$el-var-caret-left: "\f126";
$el-var-caret-right: "\f127";
$el-var-caret-up: "\f128";
$el-var-cc: "\f129";
$el-var-certificate: "\f12a";
$el-var-check: "\f12c";
$el-var-check-empty: "\f12b";
$el-var-chevron-down: "\f12d";
$el-var-chevron-left: "\f12e";
$el-var-chevron-right: "\f12f";
$el-var-chevron-up: "\f130";
$el-var-child: "\f131";
$el-var-circle-arrow-down: "\f132";
$el-var-circle-arrow-left: "\f133";
$el-var-circle-arrow-right: "\f134";
$el-var-circle-arrow-up: "\f135";
$el-var-cloud: "\f137";
$el-var-cloud-alt: "\f136";
$el-var-cog: "\f139";
$el-var-cog-alt: "\f138";
$el-var-cogs: "\f13a";
$el-var-comment: "\f13c";
$el-var-comment-alt: "\f13b";
$el-var-compass: "\f13e";
$el-var-compass-alt: "\f13d";
$el-var-credit-card: "\f13f";
$el-var-css: "\f140";
$el-var-dashboard: "\f141";
$el-var-delicious: "\f142";
$el-var-deviantart: "\f143";
$el-var-digg: "\f144";
$el-var-download: "\f146";
$el-var-download-alt: "\f145";
$el-var-dribbble: "\f147";
$el-var-edit: "\f148";
$el-var-eject: "\f149";
$el-var-envelope: "\f14b";
$el-var-envelope-alt: "\f14a";
$el-var-error: "\f14d";
$el-var-error-alt: "\f14c";
$el-var-eur: "\f14e";
$el-var-exclamation-sign: "\f14f";
$el-var-eye-close: "\f150";
$el-var-eye-open: "\f151";
$el-var-facebook: "\f152";
$el-var-facetime-video: "\f153";
$el-var-fast-backward: "\f154";
$el-var-fast-forward: "\f155";
$el-var-female: "\f156";
$el-var-file: "\f15c";
$el-var-file-alt: "\f157";
$el-var-file-edit: "\f159";
$el-var-file-edit-alt: "\f158";
$el-var-file-new: "\f15b";
$el-var-file-new-alt: "\f15a";
$el-var-film: "\f15d";
$el-var-filter: "\f15e";
$el-var-fire: "\f15f";
$el-var-flag: "\f161";
$el-var-flag-alt: "\f160";
$el-var-flickr: "\f162";
$el-var-folder: "\f166";
$el-var-folder-close: "\f163";
$el-var-folder-open: "\f164";
$el-var-folder-sign: "\f165";
$el-var-font: "\f167";
$el-var-fontsize: "\f168";
$el-var-fork: "\f169";
$el-var-forward: "\f16b";
$el-var-forward-alt: "\f16a";
$el-var-foursquare: "\f16c";
$el-var-friendfeed: "\f16e";
$el-var-friendfeed-rect: "\f16d";
$el-var-fullscreen: "\f16f";
$el-var-gbp: "\f170";
$el-var-gift: "\f171";
$el-var-github: "\f173";
$el-var-github-text: "\f172";
$el-var-glass: "\f174";
$el-var-glasses: "\f175";
$el-var-globe: "\f177";
$el-var-globe-alt: "\f176";
$el-var-googleplus: "\f178";
$el-var-graph: "\f17a";
$el-var-graph-alt: "\f179";
$el-var-group: "\f17c";
$el-var-group-alt: "\f17b";
$el-var-guidedog: "\f17d";
$el-var-hand-down: "\f17e";
$el-var-hand-left: "\f17f";
$el-var-hand-right: "\f180";
$el-var-hand-up: "\f181";
$el-var-hdd: "\f182";
$el-var-headphones: "\f183";
$el-var-hearing-impaired: "\f184";
$el-var-heart: "\f187";
$el-var-heart-alt: "\f185";
$el-var-heart-empty: "\f186";
$el-var-home: "\f189";
$el-var-home-alt: "\f188";
$el-var-hourglass: "\f18a";
$el-var-idea: "\f18c";
$el-var-idea-alt: "\f18b";
$el-var-inbox: "\f18f";
$el-var-inbox-alt: "\f18d";
$el-var-inbox-box: "\f18e";
$el-var-indent-left: "\f190";
$el-var-indent-right: "\f191";
$el-var-info-circle: "\f192";
$el-var-instagram: "\f193";
$el-var-iphone-home: "\f194";
$el-var-italic: "\f195";
$el-var-key: "\f196";
$el-var-laptop: "\f198";
$el-var-laptop-alt: "\f197";
$el-var-lastfm: "\f199";
$el-var-leaf: "\f19a";
$el-var-lines: "\f19b";
$el-var-link: "\f19c";
$el-var-linkedin: "\f19d";
$el-var-list: "\f19f";
$el-var-list-alt: "\f19e";
$el-var-livejournal: "\f1a0";
$el-var-lock: "\f1a2";
$el-var-lock-alt: "\f1a1";
$el-var-magic: "\f1a3";
$el-var-magnet: "\f1a4";
$el-var-male: "\f1a5";
$el-var-map-marker: "\f1a7";
$el-var-map-marker-alt: "\f1a6";
$el-var-mic: "\f1a9";
$el-var-mic-alt: "\f1a8";
$el-var-minus: "\f1ab";
$el-var-minus-sign: "\f1aa";
$el-var-move: "\f1ac";
$el-var-music: "\f1ad";
$el-var-myspace: "\f1ae";
$el-var-network: "\f1af";
$el-var-off: "\f1b0";
$el-var-ok: "\f1b3";
$el-var-ok-circle: "\f1b1";
$el-var-ok-sign: "\f1b2";
$el-var-opensource: "\f1b4";
$el-var-paper-clip: "\f1b6";
$el-var-paper-clip-alt: "\f1b5";
$el-var-path: "\f1b7";
$el-var-pause: "\f1b9";
$el-var-pause-alt: "\f1b8";
$el-var-pencil: "\f1bb";
$el-var-pencil-alt: "\f1ba";
$el-var-person: "\f1bc";
$el-var-phone: "\f1be";
$el-var-phone-alt: "\f1bd";
$el-var-photo: "\f1c0";
$el-var-photo-alt: "\f1bf";
$el-var-picasa: "\f1c1";
$el-var-picture: "\f1c2";
$el-var-pinterest: "\f1c3";
$el-var-plane: "\f1c4";
$el-var-play: "\f1c7";
$el-var-play-alt: "\f1c5";
$el-var-play-circle: "\f1c6";
$el-var-plurk: "\f1c9";
$el-var-plurk-alt: "\f1c8";
$el-var-plus: "\f1cb";
$el-var-plus-sign: "\f1ca";
$el-var-podcast: "\f1cc";
$el-var-print: "\f1cd";
$el-var-puzzle: "\f1ce";
$el-var-qrcode: "\f1cf";
$el-var-question: "\f1d1";
$el-var-question-sign: "\f1d0";
$el-var-quote-alt: "\f1d2";
$el-var-quote-right: "\f1d4";
$el-var-quote-right-alt: "\f1d3";
$el-var-quotes: "\f1d5";
$el-var-random: "\f1d6";
$el-var-record: "\f1d7";
$el-var-reddit: "\f1d8";
$el-var-redux: "\f1d9";
$el-var-refresh: "\f1da";
$el-var-remove: "\f1dd";
$el-var-remove-circle: "\f1db";
$el-var-remove-sign: "\f1dc";
$el-var-repeat: "\f1df";
$el-var-repeat-alt: "\f1de";
$el-var-resize-full: "\f1e0";
$el-var-resize-horizontal: "\f1e1";
$el-var-resize-small: "\f1e2";
$el-var-resize-vertical: "\f1e3";
$el-var-return-key: "\f1e4";
$el-var-retweet: "\f1e5";
$el-var-reverse-alt: "\f1e6";
$el-var-road: "\f1e7";
$el-var-rss: "\f1e8";
$el-var-scissors: "\f1e9";
$el-var-screen: "\f1eb";
$el-var-screen-alt: "\f1ea";
$el-var-screenshot: "\f1ec";
$el-var-search: "\f1ee";
$el-var-search-alt: "\f1ed";
$el-var-share: "\f1f0";
$el-var-share-alt: "\f1ef";
$el-var-shopping-cart: "\f1f2";
$el-var-shopping-cart-sign: "\f1f1";
$el-var-signal: "\f1f3";
$el-var-skype: "\f1f4";
$el-var-slideshare: "\f1f5";
$el-var-smiley: "\f1f7";
$el-var-smiley-alt: "\f1f6";
$el-var-soundcloud: "\f1f8";
$el-var-speaker: "\f1f9";
$el-var-spotify: "\f1fa";
$el-var-stackoverflow: "\f1fb";
$el-var-star: "\f1fe";
$el-var-star-alt: "\f1fc";
$el-var-star-empty: "\f1fd";
$el-var-step-backward: "\f1ff";
$el-var-step-forward: "\f200";
$el-var-stop: "\f202";
$el-var-stop-alt: "\f201";
$el-var-stumbleupon: "\f203";
$el-var-tag: "\f204";
$el-var-tags: "\f205";
$el-var-tasks: "\f206";
$el-var-text-height: "\f207";
$el-var-text-width: "\f208";
$el-var-th: "\f20b";
$el-var-th-large: "\f209";
$el-var-th-list: "\f20a";
$el-var-thumbs-down: "\f20c";
$el-var-thumbs-up: "\f20d";
$el-var-time: "\f20f";
$el-var-time-alt: "\f20e";
$el-var-tint: "\f210";
$el-var-torso: "\f211";
$el-var-trash: "\f213";
$el-var-trash-alt: "\f212";
$el-var-tumblr: "\f214";
$el-var-twitter: "\f215";
$el-var-universal-access: "\f216";
$el-var-unlock: "\f218";
$el-var-unlock-alt: "\f217";
$el-var-upload: "\f219";
$el-var-usd: "\f21a";
$el-var-user: "\f21b";
$el-var-viadeo: "\f21c";
$el-var-video: "\f21f";
$el-var-video-alt: "\f21d";
$el-var-video-chat: "\f21e";
$el-var-view-mode: "\f220";
$el-var-vimeo: "\f221";
$el-var-vkontakte: "\f222";
$el-var-volume-down: "\f223";
$el-var-volume-off: "\f224";
$el-var-volume-up: "\f225";
$el-var-w3c: "\f226";
$el-var-warning-sign: "\f227";
$el-var-website: "\f229";
$el-var-website-alt: "\f228";
$el-var-wheelchair: "\f22a";
$el-var-wordpress: "\f22b";
$el-var-wrench: "\f22d";
$el-var-wrench-alt: "\f22c";
$el-var-youtube: "\f22e";
$el-var-zoom-in: "\f22f";
$el-var-zoom-out: "\f230";

